import { defineComponent, ref, reactive } from '@vue/composition-api';
export default defineComponent({
    props: {
        value: {
            type: [Object, String],
            default: ''
        },
        verified: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root } = context;
        const lockedEmailAddr = ref('');
        const isEmailLocked = ref(true);
        const isEmailVerificationMode = ref(false);
        const currentEmail = ref('');
        const newEmail = ref('');
        const errors = reactive({
            oldEmail: '',
            newEmail: ''
        });
        /**
         * unlock email to reset
         */
        const onUnlockEmail = () => {
            isEmailLocked.value = false;
            isEmailVerificationMode.value = false;
        };
        /**
         * cancel email to reset
         */
        const onCancelEmail = () => {
            isEmailLocked.value = true;
            currentEmail.value = '';
            newEmail.value = '';
            errors.oldEmail = '';
            errors.newEmail = '';
        };
        /**
         * Sending request to update email
         */
        const onUpdateEmail = () => {
            if (currentEmail.value === props.value) {
                updateUser({ email: newEmail.value })
                    .then(() => {
                    isEmailLocked.value = true;
                    isEmailVerificationMode.value = true;
                    errors.newEmail = '';
                })
                    .catch(err => {
                    errors.newEmail = root
                        .$t(`common.errors.${err?.data?.key || 'email_invalid'}`)
                        .toString();
                });
            }
        };
        const updateUser = (userData) => {
            return globalThis.$store.dispatch('$_app/updateUser', userData);
        };
        const checkOldEmail = (event) => {
            const enteredCurrentEmail = event.target.value;
            if (enteredCurrentEmail !== props.value) {
                errors.oldEmail = root
                    .$t('common.errors.email.invalid_old_email')
                    .toString();
            }
            else {
                errors.oldEmail = '';
            }
        };
        const checkNewEmail = (event) => {
            const enteredNewEmail = event.target.value;
            if (enteredNewEmail === props.value) {
                errors.newEmail = root
                    .$t('common.errors.email.invalid_new_email')
                    .toString();
            }
            else {
                errors.newEmail = '';
            }
        };
        return {
            currentEmail,
            errors,
            isEmailLocked,
            isEmailVerificationMode,
            lockedEmailAddr,
            newEmail,
            checkNewEmail,
            checkOldEmail,
            onCancelEmail,
            onUnlockEmail,
            onUpdateEmail
        };
    }
});
