import AvatarEditor from '@/Account/components/AvatarEditor.vue';
import SaveFooter from '@/pages/profile/components/SaveFooter.vue';
import UserPhone from '@/Account/components/UserPhone.vue';
import UserEmail from '@/Account/components/UserEmail.vue';
import AccountDeletion from '@/components/AccountDeletionModal.vue';
// TODO: Merge FormSelect component with Select from uikit
import FormSelect from '@/shared/components/form/FormSelect.vue';
import EventBus from '@/shared/services/eventBus';
import RoleSwitcher from '@/Account/components/RoleSwitcher.vue';
import { computed, defineComponent, ref, reactive, watch } from '@vue/composition-api';
import { cloneDeep, isEmpty } from 'lodash';
import { useDeleteUserAccountMutation, UserRoleState } from '@/generated-types/graphql.types';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { AccountRoutes } from '@/Account/const';
export default defineComponent({
    components: {
        AccountDeletion,
        AvatarEditor,
        FormSelect,
        RoleSwitcher,
        SaveFooter,
        UserEmail,
        UserPhone
    },
    setup(_, context) {
        const { root } = context;
        const isAvatarSaving = ref(false);
        const isInformingUserUpdated = ref(false);
        const errors = reactive({
            avatarImage: '',
            firstName: '',
            lastName: ''
        });
        const user = ref({});
        const userBackup = ref(cloneDeep(user.value));
        const storedUser = computed(() => globalThis.$store.getters['$_app/user']);
        const savingPhone = ref(false);
        const isAccountDeletionModalOpened = ref(false);
        watch(() => storedUser.value, () => {
            if (storedUser.value && !savingPhone.value) {
                userBackup.value = cloneDeep(storedUser.value);
                user.value = cloneDeep(storedUser.value);
            }
        }, { immediate: true });
        const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
        const languages = computed(() => [
            { label: root.$i18n.t('common.languages.dutch'), value: 'NL' },
            { label: root.$i18n.t('common.languages.english'), value: 'EN' }
        ]);
        const { mutate: mutateUserDeletion, onDone: onUserDeletionDone } = useDeleteUserAccountMutation();
        // =======================<METHODS>======================= //
        /**
         * Updating avatar validation error
         */
        const avatarValidated = (validationError) => {
            errors.avatarImage = validationError || '';
        };
        /**
         * Emitting avatar saved event
         */
        const avatarSaved = () => {
            isAvatarSaving.value = false;
            // Emit event so the avatar will be changed in the Header component as well
            const imageUrl = user.value?.avatar_image;
            EventBus.$emit('avatarUpdated', imageUrl);
            const newUserData = changedUserData();
            if (isEmpty(newUserData)) {
                startInformingUserUpdated();
            }
            else {
                updateUser(newUserData)
                    .then(startInformingUserUpdated)
                    .catch(cancelInformingUserUpdated);
            }
        };
        const onPhoneSaved = () => {
            user.value.phone_verified = true;
            userBackup.value.phone_verified = true;
            startInformingUserUpdated();
        };
        /**
         * Reverts all changes
         */
        const onRevertChanges = () => {
            user.value = { ...userBackup.value };
            for (const key in errors) {
                errors[key] = '';
            }
        };
        /**
         * Chain of saving changes methods
         */
        const onSaveChanges = () => {
            saveChanges()
                .then(() => {
                globalThis.$store.dispatch('$_app/refetchUser');
                globalThis.$router.push(useGetLocalizedPath(`${AccountRoutes.PROFILE}/${AccountRoutes.ACCOUNT}`));
                startInformingUserUpdated();
            })
                .catch(cancelInformingUserUpdated);
        };
        /**
         * Main save changes method
         */
        const saveChanges = () => {
            // Check for valid fields
            if (!checkValidFields()) {
                return Promise.reject();
            }
            // Check for avatar change and updating it
            if (isAvatarChanged()) {
                isAvatarSaving.value = true;
                return Promise.reject();
            }
            const newUserData = changedUserData();
            return isEmpty(newUserData) ? Promise.reject() : updateUser(newUserData);
        };
        const startInformingUserUpdated = () => {
            if (!savingPhone.value) {
                userBackup.value = cloneDeep(user.value);
            }
            savingPhone.value = false;
            isInformingUserUpdated.value = true;
        };
        /**
         * Closing changes saved footer label
         */
        const cancelInformingUserUpdated = () => {
            isInformingUserUpdated.value = false;
        };
        /**
         * Validation for name fields
         */
        const checkValidFields = () => {
            const requiredCode = 'common.errors.required';
            errors.firstName = user.value?.first_name
                ? ''
                : root.$i18n.t(requiredCode).toString();
            errors.lastName = user.value?.last_name
                ? ''
                : root.$i18n.t(requiredCode).toString();
            return Object.values(errors).every(error => error === '');
        };
        /**
         * Check if avatar has changed
         */
        const isAvatarChanged = () => {
            return userBackup.value?.avatar_image !== user.value?.avatar_image;
        };
        /**
         * Check for input fields change
         */
        const hasChanges = () => {
            if (JSON.stringify(user.value) === '{}' || !userBackup.value) {
                return false;
            }
            const changed = JSON.stringify(user.value) !== JSON.stringify(userBackup.value);
            if (isInformingUserUpdated.value && changed)
                isInformingUserUpdated.value = false;
            return changed;
        };
        const isChangesSaved = () => {
            return isInformingUserUpdated.value;
        };
        /**
         * Calculates the difference between new and old data and save the
         * difference as changed data of account
         */
        const changedUserData = () => {
            const valuesByFields = {
                first_name: user.value?.first_name || '',
                last_name: user.value?.last_name || '',
                locale: user.value?.locale || ''
            };
            return Object.fromEntries(Object.entries(valuesByFields).filter(([field, value]) => userBackup.value && userBackup.value[field] !== value));
        };
        const updateUser = (userData) => {
            return globalThis.$store.dispatch('$_app/updateUser', userData);
        };
        const handleAccountDelete = () => {
            mutateUserDeletion();
            globalThis.$store.dispatch('authentication/clearTokens');
        };
        onUserDeletionDone(() => {
            // Send event
            globalThis.$gtm.trackEvent({
                event: 'account_deleted',
                category: 'accounts',
                action: 'account_deleted',
                label: 'account_deleted',
                value: user.value.account_state,
                noninteraction: false
            });
            addToast({
                text: root.$i18n.t('profile.account_page.account_deletion_modal.success_toast_message'),
                type: 'success',
                dismissAfter: 5000
            });
        });
        return {
            errors,
            isAccountDeletionModalOpened,
            isAvatarSaving,
            languages,
            savingPhone,
            user,
            UserRoleState,
            avatarSaved,
            avatarValidated,
            cancelInformingUserUpdated,
            handleAccountDelete,
            hasChanges,
            isChangesSaved,
            onPhoneSaved,
            onRevertChanges,
            onSaveChanges
        };
    }
});
