import { defineComponent, ref, onMounted, computed } from '@vue/composition-api';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import Access from '@/components/Access.vue';
import { UserRoleState } from '@/generated-types/graphql.types';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    components: {
        Access
    },
    setup() {
        const expanded = ref({
            account: true,
            finance: false,
            organisation: false
        });
        const currentUser = computed(() => globalThis.$store.getters['$_app/user']);
        const hasOrganisation = computed(() => !!currentUser.value.organisation_id);
        onMounted(() => {
            globalThis.$store.dispatch('$_app/loadUser');
        });
        const logout = () => {
            globalThis.$store.dispatch('authentication/logout');
        };
        const onboardingRedirect = () => {
            if (!(currentUser.value && currentUser.value.organisation_id)) {
                globalThis.$router.push({
                    name: ROUTE_LIST_NAMES.ONBOARDING.ORGANISATION_DETAILS
                });
            }
        };
        const openSubmenu = (type) => {
            expanded.value = Object.keys(expanded.value).reduce((result, k) => {
                // Reduce keys to object of key/value pairs
                // where key match is set to true
                return { ...result, [k]: type === k };
            }, {});
        };
        return {
            expanded,
            hasOrganisation,
            UserRoleState,
            ROUTE_LIST_NAMES,
            logout,
            onboardingRedirect,
            openSubmenu,
            useGetLocalizedPath
        };
    }
});
