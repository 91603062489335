import { defineComponent, ref, watch } from '@vue/composition-api';
import { AVAILABLE_IMAGE_FORMATS, IMAGE_VALIDATION_ERRORS, IMAGE_VALIDATION_RULES, AVAILABLE_IMAGE_MEDIA_TYPES, AVAILABLE_IMAGE_EXTENSIONS } from '@/Account/const';
import { removeAvatar, removeOrgLogo, uploadImage } from '@/shared/api-modules/application.api';
var ProcessState;
(function (ProcessState) {
    ProcessState[ProcessState["SELECTING"] = 0] = "SELECTING";
    ProcessState[ProcessState["SELECTED"] = 1] = "SELECTED";
    ProcessState[ProcessState["UPLOADING"] = 2] = "UPLOADING";
    ProcessState[ProcessState["UPLOADED"] = 3] = "UPLOADED";
    ProcessState[ProcessState["REMOVING"] = 4] = "REMOVING";
    ProcessState[ProcessState["REMOVED"] = 5] = "REMOVED";
    ProcessState[ProcessState["REPLACING"] = 6] = "REPLACING";
    ProcessState[ProcessState["REPLACED"] = 7] = "REPLACED";
    ProcessState[ProcessState["CLEANING"] = 8] = "CLEANING";
})(ProcessState || (ProcessState = {}));
export default defineComponent({
    props: {
        value: {
            type: String,
            default: ''
        },
        id: {
            type: Number,
            default: 0
        },
        memorizable: {
            type: Boolean,
            default: false
        },
        imageType: {
            type: String,
            default: 'avatar'
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const availableImageExtensions = AVAILABLE_IMAGE_EXTENSIONS.join(', ');
        const photoInput = ref(null);
        const isImageLoading = ref(false);
        const isImageDragging = ref(false);
        const errorCode = ref('');
        const errorMessage = ref('');
        const processStates = ref([]);
        const imgId = ref(undefined);
        const _loadImage = (imageUrl) => {
            if (imageUrl) {
                isImageLoading.value = true;
            }
            emit('input', imageUrl);
        };
        const _terminateProcess = () => {
            processStates.value.push(ProcessState.CLEANING);
        };
        const _uploadImage = () => {
            if (!props.memorizable) {
                return;
            }
            if (props.imageType === 'feedback') {
                processStates.value.push(ProcessState.UPLOADED);
                return;
            }
            const fileModel = new FormData();
            fileModel.append('image', photoInput?.value?.files?.[0]);
            fileModel.append('entity_type', props.imageType === 'avatar' ? 'ACCOUNT' : 'ORGANISATION');
            if (props.id) {
                fileModel.append('entity_id', props.id.toString());
            }
            uploadImage(fileModel).then(response => {
                if (response.image_url) {
                    _loadImage(response.image_url);
                    imgId.value = response.image_id;
                    processStates.value.push(ProcessState.UPLOADED);
                }
                else {
                    _terminateProcess();
                }
            }, error => {
                _terminateProcess();
            });
        };
        const _removeImage = () => {
            if (!props.memorizable) {
                return;
            }
            if (props.imageType === 'feedback') {
                processStates.value.push(ProcessState.REMOVED);
                return;
            }
            if (props.imageType === 'organisation') {
                removeOrgLogo(props.id).then(() => processStates.value.push(ProcessState.REMOVED), error => {
                    console.log('Photo removing error', error);
                    _terminateProcess();
                });
            }
            else {
                removeAvatar(props.id).then(() => processStates.value.push(ProcessState.REMOVED), error => {
                    console.log('Photo removing error', error);
                    _terminateProcess();
                });
            }
        };
        const _prepareImage = () => {
            const file = photoInput?.value?.files?.[0];
            errorCode.value = '';
            if (photoInput?.value?.files?.length !== 1) {
                errorCode.value = IMAGE_VALIDATION_ERRORS.MORE_THAN_ONE;
            }
            else if (file && !AVAILABLE_IMAGE_MEDIA_TYPES.includes(file.type)) {
                errorCode.value = IMAGE_VALIDATION_ERRORS.UNAVAILABLE_FORMAT;
            }
            else if (file && file.size > IMAGE_VALIDATION_RULES.MAX_SIZE) {
                errorCode.value = IMAGE_VALIDATION_ERRORS.TOO_BIG;
            }
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (typeof reader.result !== 'string') {
                    _terminateProcess();
                    return;
                }
                const imageUrl = reader.result;
                const image = new Image();
                image.src = imageUrl;
                image.onload = () => {
                    if (errorCode.value) {
                        _terminateProcess();
                    }
                    else {
                        _loadImage(imageUrl);
                        processStates.value.push(ProcessState.UPLOADING);
                    }
                };
            };
            reader.onerror = error => {
                console.log('Photo loading error: ', error);
                _terminateProcess();
            };
        };
        const _stateReacting = () => {
            const states = processStates.value;
            if (!states.length) {
                return;
            }
            const lastState = states[states.length - 1];
            switch (lastState) {
                case ProcessState.SELECTING:
                    photoInput.value?.click();
                    break;
                case ProcessState.SELECTED:
                    if (photoInput.value) {
                        _prepareImage();
                    }
                    else {
                        _terminateProcess();
                    }
                    break;
                case ProcessState.UPLOADING:
                    if (states.includes(ProcessState.REPLACING)) {
                        processStates.value.push(ProcessState.REMOVING);
                    }
                    else {
                        _uploadImage();
                    }
                    break;
                case ProcessState.UPLOADED:
                    if (states.includes(ProcessState.REPLACING)) {
                        processStates.value.push(ProcessState.REPLACED);
                    }
                    else {
                        emit('action', 'uploaded');
                        emit('memorized', imgId.value);
                        processStates.value.push(ProcessState.CLEANING);
                    }
                    photoInput.value.value = null;
                    break;
                case ProcessState.REMOVING:
                    if (!states.includes(ProcessState.REPLACING)) {
                        _loadImage('');
                    }
                    _removeImage();
                    break;
                case ProcessState.REMOVED:
                    if (states.includes(ProcessState.REPLACING)) {
                        _uploadImage();
                    }
                    else {
                        emit('action', 'removed');
                        emit('memorized');
                        processStates.value.push(ProcessState.CLEANING);
                    }
                    break;
                case ProcessState.REPLACING:
                    processStates.value.push(ProcessState.SELECTING);
                    break;
                case ProcessState.REPLACED:
                    emit('action', 'updated');
                    emit('memorized', imgId.value);
                    processStates.value.push(ProcessState.CLEANING);
                    break;
                case ProcessState.CLEANING:
                    processStates.value = [];
                    break;
            }
        };
        const imageLoaded = () => {
            isImageLoading.value = false;
        };
        const uploadPhoto = () => {
            processStates.value = [ProcessState.SELECTING];
        };
        const replacePhoto = () => {
            processStates.value = [ProcessState.REPLACING];
        };
        const removePhoto = () => {
            processStates.value = [ProcessState.REMOVING];
        };
        const photoSelected = () => {
            processStates.value.push(ProcessState.SELECTED);
        };
        const dragLeavePhoto = () => {
            isImageDragging.value = false;
        };
        const _dropPhoto = (event) => {
            event.preventDefault();
            isImageDragging.value = false;
            photoInput.value.files = event?.dataTransfer?.files;
        };
        const dropPhotoToUpload = (event) => {
            _dropPhoto(event);
            processStates.value = [ProcessState.SELECTED];
        };
        const dropPhotoToReplace = (event) => {
            _dropPhoto(event);
            processStates.value = [ProcessState.REPLACING, ProcessState.SELECTED];
        };
        const dragOverPhoto = (event) => {
            event.preventDefault();
            isImageDragging.value = true;
        };
        watch(processStates, () => _stateReacting(), { immediate: true });
        watch(() => props.memorizable, () => {
            if (props.memorizable) {
                _stateReacting();
            }
        }, { immediate: true });
        watch(errorCode, code => {
            switch (code) {
                case IMAGE_VALIDATION_ERRORS.MORE_THAN_ONE:
                    errorMessage.value = root
                        .$t('common.errors.image.more_than_one_image')
                        .toString();
                    break;
                case IMAGE_VALIDATION_ERRORS.TOO_BIG:
                    errorMessage.value = root
                        .$t('common.errors.image.too_big')
                        .toString();
                    break;
                case IMAGE_VALIDATION_ERRORS.UNAVAILABLE_FORMAT: {
                    const availableFormats = Object.keys(AVAILABLE_IMAGE_FORMATS).join(', ');
                    errorMessage.value = root
                        .$t('common.errors.image.image_wrong_format', {
                        available_formats: availableFormats
                    })
                        .toString();
                    break;
                }
                default:
                    errorMessage.value = '';
            }
            emit('validated', errorMessage.value);
        }, { immediate: true });
        return {
            availableImageExtensions,
            errorMessage,
            isImageDragging,
            isImageLoading,
            photoInput,
            dragLeavePhoto,
            dragOverPhoto,
            dropPhotoToReplace,
            dropPhotoToUpload,
            imageLoaded,
            photoSelected,
            removePhoto,
            replacePhoto,
            uploadPhoto
        };
    }
});
