/**
 * Settings specific to avatar images
 */
export const AVAILABLE_IMAGE_FORMATS = {
    JPEG: {
        mediaType: 'image/jpeg',
        extensions: ['.jpeg', '.jpg']
    },
    PNG: {
        mediaType: 'image/png',
        extensions: ['.png']
    },
    SVG: {
        mediaType: 'image/svg+xml',
        extensions: ['.svg']
    },
    WEBP: {
        mediaType: 'image/webp',
        extensions: ['.webp']
    }
};
export const IMAGE_VALIDATION_ERRORS = {
    MORE_THAN_ONE: 'moreThanOne',
    TOO_BIG: 'tooBig',
    UNAVAILABLE_FORMAT: 'unavailableFormat'
};
export const IMAGE_VALIDATION_RULES = {
    MAX_SIZE: 20000000
};
export const AVAILABLE_IMAGE_MEDIA_TYPES = Object.entries(AVAILABLE_IMAGE_FORMATS).map(([_, formatInfo]) => formatInfo.mediaType);
export const AVAILABLE_IMAGE_EXTENSIONS = Object.entries(AVAILABLE_IMAGE_FORMATS).map(([_, formatInfo]) => formatInfo.extensions.join(', '));
export var AccountRoutes;
(function (AccountRoutes) {
    AccountRoutes["ACCOUNT"] = "account";
    AccountRoutes["PROFILE"] = "profile";
})(AccountRoutes || (AccountRoutes = {}));
