import { defineComponent, ref, watch, reactive } from '@vue/composition-api';
import { ServerErrors } from '@/util/graphql';
import { withAuthMutationHook } from '@/util/hooks';
import { useUpdateUserStateMutation } from '@/generated-types/graphql.types';
import PhoneNumber from '@/uikit/PhoneNumber';
import { toCamelCase } from '@/util/transform';
import { useResendTimer } from '@/util/hooks';
import { timeZero } from '@/util/filters';
const updateProfile = withAuthMutationHook(useUpdateUserStateMutation);
export default defineComponent({
    components: {
        PhoneNumber
    },
    props: {
        value: {
            type: [Object, String],
            default: ''
        },
        verified: {
            type: Boolean,
            default: false
        },
        savingPhone: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const errors = reactive({
            newPhoneError: { key: 'phone.validation', text: '' },
            verificationError: {
                key: 'invalid_verification_code',
                text: ''
            }
        });
        const isPhoneLocked = ref(true);
        const lockedPhoneNum = ref('');
        const isPhoneVerificationMode = ref(false);
        const newPhoneNum = ref({});
        const phoneVerificationCode = ref(undefined);
        const { mutate } = updateProfile({ clientId: 'legacy' });
        const { timer, startTimer } = useResendTimer(root);
        /**
         * Watching value changes to update locked phone number
         */
        watch(() => props.value, () => {
            if (props.value) {
                lockedPhoneNum.value = props.value;
            }
        }, { immediate: true });
        /**
         * Watching user locale changes to update the error text
         */
        watch(() => globalThis.$i18n.locale, () => {
            errors.newPhoneError.text = errors.newPhoneError.text
                ? root.$i18n.t(`common.errors.${errors.newPhoneError.key}`).toString()
                : '';
            errors.verificationError.text = errors.verificationError.text
                ? root.$i18n
                    .t(`common.errors.${errors.verificationError.key}`)
                    .toString()
                : '';
        }, { immediate: true });
        /**
         * Unlock phone to reset
         */
        const onUnlockPhone = () => {
            isPhoneLocked.value = false;
            isPhoneVerificationMode.value = false;
        };
        /**
         * Sending resend verification mutation
         */
        const onVerifyPhone = async () => {
            newPhoneNum.value.formatted_number = props.value;
            startTimer();
            globalThis.$store
                .dispatch('$_app/resendVerification', 'phone')
                .then(() => {
                isPhoneLocked.value = true;
                isPhoneVerificationMode.value = true;
            })
                .catch(() => {
                errors.verificationError.key = 'something_wrong';
                errors.verificationError.text = root
                    .$t(`common.errors.${errors.verificationError.key}`)
                    .toString();
            });
        };
        /**
         * Cancel update phone number
         */
        const onCancelPhone = () => {
            isPhoneLocked.value = true;
            newPhoneNum.value = {};
            errors.newPhoneError.text = ''; // Reset the phone validation form error
            errors.verificationError.text = ''; // Reset the code validation form error
        };
        /**
         * Updating phone number
         */
        const onUpdatePhone = () => {
            emit('onSavingPhone', true);
            startTimer();
            errors.newPhoneError.text = '';
            errors.verificationError.text = '';
            phoneVerificationCode.value = '';
            mutate({ profile: { phone: toCamelCase(newPhoneNum.value) } })
                .then(() => {
                isPhoneLocked.value = true;
                isPhoneVerificationMode.value = true;
                errors.newPhoneError.text = '';
                emit('onSavingPhone', false);
            })
                .catch(({ graphQLErrors }) => {
                if (graphQLErrors) {
                    const error = ServerErrors.extractGQLError(graphQLErrors);
                    if (error?.phone?.error?.key) {
                        errors.newPhoneError.key = error?.phone?.error?.key;
                    }
                    errors.newPhoneError.text = root
                        .$t(`common.errors.${errors.newPhoneError.key}`)
                        .toString();
                }
            });
        };
        /**
         * check phone verification code
         *
         * check response from backend
         * reset phone number
         * update phone after verification
         */
        const onCheckPhoneVerificationCode = () => {
            emit('onSavingPhone', true);
            globalThis.$store
                .dispatch('authentication/verifyPhoneCode', phoneVerificationCode.value)
                .then(() => {
                lockedPhoneNum.value = newPhoneNum.value;
                emit('onPhoneSaved');
                isPhoneVerificationMode.value = false;
            })
                .catch(() => {
                errors.verificationError.text = root
                    .$t(`common.errors.${errors.verificationError.key}`)
                    .toString();
                emit('onPhoneSavingError');
            });
        };
        return {
            errors,
            isPhoneLocked,
            isPhoneVerificationMode,
            lockedPhoneNum,
            newPhoneNum,
            phoneVerificationCode,
            timer,
            onCancelPhone,
            onCheckPhoneVerificationCode,
            onUnlockPhone,
            onUpdatePhone,
            onVerifyPhone,
            timeZero
        };
    }
});
