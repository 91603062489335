var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"notifications"},[_c('h1',{staticClass:"notifications__title"},[_vm._v(" "+_vm._s(_vm.$t('notifications.manage_notifications.title'))+" ")]),_c('p',{staticClass:"notifications__description"},[_vm._v(" "+_vm._s(_vm.$t('notifications.manage_notifications.description'))+" ")]),_c('h2',{staticClass:"notifications__subtitle"},[_vm._v(" "+_vm._s(_vm.$t('notifications.manage_notifications.subtitle'))+" ")]),_c('ul',{staticClass:"notifications__steps"},[_c('li',{staticClass:"notifications__steps__view"},[_vm._v(" "+_vm._s(_vm.$t('notifications.manage_notifications.steps.view'))+" ")]),_c('li',{staticClass:"notifications__steps__toggle"},[_vm._v(" "+_vm._s(_vm.$t('notifications.manage_notifications.steps.toggle'))+" ")]),_c('li',{staticClass:"notifications__steps__save"},[_vm._v(" "+_vm._s(_vm.$t('notifications.manage_notifications.steps.save'))+" ")])]),_c('div',{staticClass:"notifications__legend"},[_c('span',[_c('i',{staticClass:"fa-solid fa-envelope fa-lg"}),_c('span',[_vm._v(_vm._s(_vm.$t('common.email')))])]),_c('span',[_c('i',{staticClass:"fa-solid fa-comment-sms fa-lg"}),_c('span',[_vm._v(_vm._s(_vm.$t('common.sms')))])]),_c('span',[_c('i',{staticClass:"fa-solid fa-message fa-lg"}),_c('span',[_vm._v(_vm._s(_vm.$t('notifications.manage_notifications.internal_message')))])])]),_c('form',{staticClass:"notifications__form",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"notifications__form__events-list"},[(_vm.adminNotifications.length > 0)?_c('InputHeader',{attrs:{"title":_vm.$t(
            ("notifications.manage_notifications.event_scopes." + (_vm.EventScope.Admin))
          )}}):_vm._e(),_vm._l((_vm.adminNotifications),function(event){return _c('FormCheckbox',{key:event.event_id,attrs:{"icon-classes":[
          event.email ? 'fa-solid fa-envelope fa-lg' : '',
          event.sms ? 'fa-solid fa-comment-sms fa-lg' : '',
          event.event_type !== _vm.EventNotificationType.System
            ? 'fa-solid fa-message fa-lg'
            : ''
        ],"disabled":!event.is_editable,"inline":true,"label":_vm.$t(
            ("notifications.manage_notifications.events_description." + (event.event_name))
          )},on:{"click":function($event){_vm.isFormChanged = true}},model:{value:(_vm.form.events[event.event_id]),callback:function ($$v) {_vm.$set(_vm.form.events, event.event_id, $$v)},expression:"form.events[event.event_id]"}})}),(_vm.authNotifications.length > 0)?_c('InputHeader',{attrs:{"title":_vm.$t(
            ("notifications.manage_notifications.event_scopes." + (_vm.EventScope.Authentication))
          )}}):_vm._e(),_vm._l((_vm.authNotifications),function(event){return _c('FormCheckbox',{key:event.event_id,attrs:{"disabled":!event.is_editable,"icon-classes":[
          event.email ? 'fa-solid fa-envelope fa-lg' : '',
          event.sms ? 'fa-solid fa-comment-sms fa-lg' : '',
          event.event_type !== _vm.EventNotificationType.System
            ? 'fa-solid fa-message fa-lg'
            : ''
        ],"inline":true,"label":_vm.$t(
            ("notifications.manage_notifications.events_description." + (event.event_name))
          )},on:{"click":function($event){_vm.isFormChanged = true}},model:{value:(_vm.form.events[event.event_id]),callback:function ($$v) {_vm.$set(_vm.form.events, event.event_id, $$v)},expression:"form.events[event.event_id]"}})}),(_vm.bookingNotifications.length > 0)?_c('InputHeader',{attrs:{"title":_vm.$t(
            ("notifications.manage_notifications.event_scopes." + (_vm.EventScope.Booking))
          )}}):_vm._e(),_vm._l((_vm.bookingNotifications),function(event){return _c('FormCheckbox',{key:event.event_id,attrs:{"disabled":!event.is_editable,"icon-classes":[
          event.email ? 'fa-solid fa-envelope fa-lg' : '',
          event.sms ? 'fa-solid fa-comment-sms fa-lg' : '',
          event.event_type !== _vm.EventNotificationType.System
            ? 'fa-solid fa-message fa-lg'
            : ''
        ],"inline":true,"label":_vm.$t(
            ("notifications.manage_notifications.events_description." + (event.event_name))
          )},on:{"click":function($event){_vm.isFormChanged = true}},model:{value:(_vm.form.events[event.event_id]),callback:function ($$v) {_vm.$set(_vm.form.events, event.event_id, $$v)},expression:"form.events[event.event_id]"}})}),(_vm.locationNotifications.length > 0)?_c('InputHeader',{attrs:{"title":_vm.$t(
            ("notifications.manage_notifications.event_scopes." + (_vm.EventScope.Location))
          )}}):_vm._e(),_vm._l((_vm.locationNotifications),function(event){return _c('FormCheckbox',{key:event.event_id,attrs:{"disabled":!event.is_editable,"icon-classes":[
          event.email ? 'fa-solid fa-envelope fa-lg' : '',
          event.sms ? 'fa-solid fa-comment-sms fa-lg' : '',
          event.event_type !== _vm.EventNotificationType.System
            ? 'fa-solid fa-message fa-lg'
            : ''
        ],"inline":true,"label":_vm.$t(
            ("notifications.manage_notifications.events_description." + (event.event_name))
          )},on:{"click":function($event){_vm.isFormChanged = true}},model:{value:(_vm.form.events[event.event_id]),callback:function ($$v) {_vm.$set(_vm.form.events, event.event_id, $$v)},expression:"form.events[event.event_id]"}})})],2)]),_c('p',{staticClass:"notifications__footer"},[_vm._v(" "+_vm._s(_vm.$t('notifications.manage_notifications.footer'))+" ")]),_c('SaveFooter',{attrs:{"on-revert":_vm.onRevertChanges,"on-save":_vm.onSaveChanges,"on-close":_vm.onClose,"is-changed":_vm.hasChanges,"is-saved":_vm.isChangesSaved}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }