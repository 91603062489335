var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.errorMessage)?_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.value)?_c('div',{staticClass:"avatar"},[_c('div',{staticClass:"avatar-wrap"},[(_vm.isImageLoading)?_c('div',{staticClass:"loader"}):_vm._e(),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isImageLoading),expression:"!isImageLoading"}],staticClass:"avatar-image",class:{
          'avatar-dragging': _vm.isImageDragging,
          'organisation-image': _vm.imageType === 'organisation'
        },attrs:{"src":(_vm.value + "?tr=w-" + (_vm.imageType !== 'organisation' ? 100 : _vm.$mq.phone ? 310 : 565) + ",h-100,cm-pad_resize"),"alt":"Image","width":_vm.imageType !== 'organisation' ? 100 : _vm.$mq.phone ? 310 : 565,"height":100},on:{"load":_vm.imageLoaded,"dragleave":_vm.dragLeavePhoto,"drop":_vm.dropPhotoToReplace,"dragover":_vm.dragOverPhoto}}),_c('div',{staticClass:"avatar-remove",on:{"click":_vm.removePhoto}},[_c('i',{staticClass:"fa-solid fa-xmark fa-xs avatar-remove__cross"})])]),_c('WMButton',{attrs:{"data-gtm":"upload_user_avatar_button","small":"","color":"accent","type":"button","icon-class-name":"fa-solid fa-upload fa-xs"},on:{"click":_vm.replacePhoto}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('profile.avatar.upload_new_photo')))])])],1):_c('div',{staticClass:"avatar"},[_c('div',{class:{
        'avatar-dummy': true,
        'avatar-dragging': _vm.isImageDragging,
        'organisation-dummy': _vm.imageType === 'organisation'
      },on:{"click":_vm.uploadPhoto,"dragleave":_vm.dragLeavePhoto,"drop":_vm.dropPhotoToUpload,"dragover":_vm.dragOverPhoto}},[(_vm.imageType === 'avatar' || _vm.imageType === 'feedback')?_c('i',{staticClass:"fa-solid fa-user fa-lg normal"}):(_vm.imageType === 'organisation')?_c('img',{staticClass:"normal organisation-placeholder",attrs:{"src":"/img/common/logo-placeholder.png","alt":"Organisation logo","width":200,"height":100}}):_vm._e(),_c('i',{staticClass:"fa-solid fa-upload fa-lg hover"})])]),_c('input',{ref:"photoInput",attrs:{"name":"photoInput","type":"file","hidden":"","accept":_vm.availableImageExtensions},on:{"change":_vm.photoSelected}})])}
var staticRenderFns = []

export { render, staticRenderFns }