import { computed, defineComponent } from '@vue/composition-api';
import Toggle, { Option } from '@/uikit/Toggle.vue';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import { AccountState, useUpdateUserStateMutation } from '@/generated-types/graphql.types';
import { withAuthMutationHook } from '@/util/hooks';
const updateProfile = withAuthMutationHook(useUpdateUserStateMutation);
export default defineComponent({
    components: {
        Toggle,
        Option
    },
    setup(_, context) {
        const { root, emit } = context;
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const { mutate } = updateProfile({ clientId: 'legacy' });
        const changeRole = async (value) => {
            if (user.value.account_state !== value) {
                await mutate({ profile: { accountState: value } });
                emit('change', value);
                const isBooker = value === AccountState.Booker;
                globalThis.$store.dispatch('$_app/refetchUser');
                const toastGreeting = root.$i18n.t('profile.account_modal.toast_greeting', {
                    user: user.value?.first_name
                });
                const toastLoggedIn = root.$i18n.t('profile.account_modal.toast_logged_in', {
                    role: isBooker ? 'Booker' : 'Vendor'
                });
                const postfix = root.$i18n.t(`profile.account_modal.toast_postfix_${isBooker ? 'booker' : 'vendor'}_label`);
                //TODO: refactor this piece in future;
                const toastHTML = `
            <div style="display: flex;">
                <div style="margin-right: 10px;">
                    <i class="fa-solid fa-face-smile fa-lg"></i>
                </div>
                <div>
                    <strong style="display: block; margin-bottom: 10px;">${toastGreeting}</strong>
                    <p style="margin-bottom: 5px;">${toastLoggedIn}</p>
                    <p>${postfix}</p>
                </div>
            </div>`;
                const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
                addToast({
                    text: toastHTML,
                    type: 'info',
                    dismissAfter: 4000
                });
            }
        };
        return {
            user,
            AccountState,
            changeRole
        };
    }
});
